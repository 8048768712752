import {
    Typography,
    Box
} from '@mui/material';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';

const ArticleContainer = ({ heading, children, dataAOS, delay }) => {
    useEffect(() => {
        AOS.init()
    }, [])
    return (
            <Box data-aos={dataAOS} data-aos-delay={delay} display="flex" gridColumn={"span 1"} flexDirection="column">
                <Typography sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 500,
                    fontSize: 15,
                    mb: 1.5
                }}>
                    {heading}
                </Typography>
                {children}
            </Box>
    )
}

export default ArticleContainer;