import { Box } from "@mui/material";
import BackButton from "../components/Buttons/BackButton";
import Heading from "../components/Heading/Heading";
import Navbar from "../components/Nav/Navbar";
import Head from "next/head";
import { useSelector, useDispatch } from "react-redux";
import imageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Headline } from "../components/Layouts/Posts";
import {
  DATASET,
  getDate,
  PROJECT_ID,
  returnApiMain,
  timeElapsed
} from "../utils";
import { Showcase } from "../components/Layouts/Posts";
import PageNumbering from "../components/Paging/PageNumbering";
import CategoryFilter from "../components/Filtering/CategoryFilter";
import NewArticles from "../components/Articles/NewArticles";
import PopularArticles from "../components/Articles/PopularArticles";
import SuggestedArticles from "../components/Articles/SuggestedArticles";
import { loadingCategory } from "../redux/actions";
import Footer from "../components/Footers/Footer";
import MainBanner from "../components/Banners/MainBanner";
import AOS from "aos";
import "aos/dist/aos.css";
import dynamic from "next/dynamic";

const Main = dynamic(() => import("../components/Layouts/Containers/Main"), {
  ssr: false
});

export default function Home({
  posts,
  new_article_list,
  popular_article_list,
  suggested_article_list,
  value
}) {
  useEffect(() => {
    AOS.init();
  }, []);

  const router = useRouter();
  const dispatch = useDispatch();
  const filteredTag = useSelector((state) => state.selectedCategory.selected);
  const categoryIsLoading = useSelector(
    (state) => state.selectedCategory.loading
  );
  const nextPageData = useSelector((state) => state.pageNumbering.pages);

  const [mappedPosts, setMappedPosts] = useState([]);
  const [mappedNewArticles, setMappedNewArticles] = useState([]);
  const [mappedPopularArticles, setMappedPopularArticles] = useState([]);
  const [mappedSuggestedArticles, setMappedSuggestedArticles] = useState([]);

  const imgBuilder = imageUrlBuilder({
    projectId: PROJECT_ID,
    dataset: DATASET,
    useCdn: true
  });

  useEffect(() => {
    if (filteredTag) {
      (async () => {
        dispatch(loadingCategory(true));
        const query = encodeURIComponent(
          `*[ _type == "post" && categories[]->title match "${filteredTag}" ][0..6] | order (_createdAt asc) { title, "category": categories[]->title[0], _createdAt, slug, body, mainImage, "authorName": author->name, "authorImage": author->image, likes }`
        );
        const result = await fetch(
          `https://nk5lirbt.api.sanity.io/v1/data/query/production?query=${query}`
        ).then((res) => res.json());

        const res =
          !result.result || !result.result.length ? [] : result.result;

        dispatch(loadingCategory(false));

        setMappedPosts(
          res.map((data) => ({
            ...data,
            mainImage: imgBuilder.image(data.mainImage),
            authorImage: imgBuilder.image(data.authorImage),
            createdAt: getDate(data._createdAt),
            createdTime: timeElapsed(data._createdAt)
          }))
        );
      })();
    } else {
      setMappedPosts(
        posts.map((data) => {
          return {
            ...data,
            mainImage: imgBuilder.image(data.mainImage),
            authorImage: imgBuilder.image(data.authorImage),
            createdAt: getDate(data._createdAt),
            createdTime: timeElapsed(data._createdAt)
          };
        })
      );
    }
  }, [filteredTag]);

  useEffect(() => {
    if (posts.length && !filteredTag && !nextPageData.length) {
      setMappedPosts(
        posts.map((data) => {
          return {
            ...data,
            mainImage: imgBuilder.image(data.mainImage),
            authorImage: imgBuilder.image(data.authorImage),
            createdAt: getDate(data._createdAt),
            createdTime: timeElapsed(data._createdAt)
          };
        })
      );
    } else if (nextPageData.length) {
      setMappedPosts(
        nextPageData.map((data) => ({
          ...data,
          mainImage: imgBuilder.image(data.mainImage),
          authorImage: imgBuilder.image(data.authorImage),
          createdAt: getDate(data._createdAt),
          createdTime: timeElapsed(data._createdAt)
        }))
      );
    } else {
      setMappedPosts([]);
    }
  }, [posts, nextPageData]);

  useEffect(() => {
    if (new_article_list.length) {
      setMappedNewArticles(
        new_article_list.map((data) => {
          return {
            ...data,
            mainImage: imgBuilder.image(data.mainImage)
          };
        })
      );
    } else {
      setMappedNewArticles([]);
    }
  }, [new_article_list]);

  useEffect(() => {
    if (popular_article_list.length) {
      setMappedPopularArticles(
        popular_article_list.map((data) => {
          return {
            ...data,
            mainImage: imgBuilder.image(data.mainImage)
          };
        })
      );
    } else {
      setMappedPopularArticles([]);
    }
  }, [popular_article_list]);

  useEffect(() => {
    if (suggested_article_list.length) {
      setMappedSuggestedArticles(
        suggested_article_list.map((data) => {
          return {
            ...data,
            mainImage: imgBuilder.image(data.mainImage)
          };
        })
      );
    } else {
      setMappedSuggestedArticles([]);
    }
  }, [suggested_article_list]);

  return (
    <Box>
      <Head>
        <title>Linguo Blog</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Navbar />
      <Box
        data-aos="flip-up"
        sx={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          background: "#008689",
          px: { xs: "2%", sm: "6%", md: "10%", lg: "15%", xl: "20%" },
          pt: { xs: "55%", md: 10 }
        }}>
        <MainBanner />
      </Box>
      <Heading
        caption={
          filteredTag
            ? `Mencari blog posts di kategori ${filteredTag}`
            : "Berisi tips, edukasi, hiburan dan lain lain seputar bahasa!"
        }>
        {filteredTag ? filteredTag : "Linguo Blog"}
      </Heading>
      <Main>
        <BackButton onClick={() => window.location.replace(returnApiMain())}>
          Back to home
        </BackButton>
        {!categoryIsLoading && (
          <>
            <Box sx={{ mt: 3 }}>
              {mappedPosts &&
                mappedPosts.map(
                  (post, index) =>
                    index === 0 && (
                      <Headline
                        dataAOS="flip-up"
                        key={index}
                        title={post.title}
                        image={post.mainImage}
                        caption={post.body[0].children[0].text}
                        authorImage={post.authorImage}
                        authorName={post.authorName}
                        createdAt={post.createdTime}
                        category={post.category}
                        textCount={post}
                        onClick={() =>
                          router.push(`/post/${post.slug.current}`)
                        }
                      />
                    )
                )}
            </Box>
            <Box
              sx={{
                mt: 10,
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)"
                },
                gap: 6
              }}>
              {mappedPosts &&
                mappedPosts.map(
                  (post, index) =>
                    index !== 0 && (
                      <Showcase
                        dataAOS="zoom-in-down"
                        category={post.category}
                        key={index}
                        title={post.title}
                        image={post.mainImage}
                        caption={post.body[0].children[0].text}
                        authorImage={post.authorImage}
                        authorName={post.authorName}
                        createdAt={post.createdTime}
                        onClick={() =>
                          router.push(`/post/${post.slug.current}`)
                        }
                      />
                    )
                )}
            </Box>
            <PageNumbering />
            <CategoryFilter />
          </>
        )}
        <Box
          sx={{
            mt: "7%",
            display: "grid",
            gap: 4,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)"
            }
          }}>
          <NewArticles posts={mappedNewArticles} />
          <PopularArticles posts={mappedPopularArticles} />
          <SuggestedArticles posts={mappedSuggestedArticles} />
        </Box>
      </Main>
      <Footer />
    </Box>
  );
}

export const getServerSideProps = async (pageContext) => {
  const query = encodeURIComponent(
    `*[ _type == "post" ][0..6] | order (_createdAt asc) { title, "category": categories[]->title[0], _createdAt, slug, body, mainImage, "authorName": author->name, "authorImage": author->image, likes }`
  );
  const newArticleQry = encodeURIComponent(
    '*[ _type == "post" ][0...3] | order (_createdAt desc) { title, slug, body, mainImage, "category": categories[]->title[0] }'
  );
  const popularArticleQry = encodeURIComponent(
    '*[ _type == "post" ][0...3] | order (likes desc) { title, slug, body, mainImage, "category": categories[]->title[0] }'
  );
  const suggestedArticleQry = encodeURIComponent(
    '*[ _type == "post"  && suggested == true ][0...3] | order (likes desc) { title, slug, body, mainImage, "category": categories[]->title[0] }'
  );

  const url = (qry) =>
    `https://${PROJECT_ID}.api.sanity.io/v1/data/query/production?query=${qry}`;

  const result = await fetch(url(query)).then((res) => res.json());
  const new_article_list = await fetch(url(newArticleQry)).then((res) =>
    res.json()
  );
  const popular_article_list = await fetch(url(popularArticleQry)).then((res) =>
    res.json()
  );
  const suggested_article_list = await fetch(url(suggestedArticleQry)).then(
    (res) => res.json()
  );

  if (!result.result || !result.result.length) {
    return {
      props: {
        posts: [],
        new_article_list: !new_article_list.error
          ? new_article_list.result
          : [],
        popular_article_list: !popular_article_list.error
          ? popular_article_list.result
          : [],
        suggested_article_list: !suggested_article_list.error
          ? suggested_article_list.result
          : []
      }
    };
  } else {
    return {
      props: {
        posts: result.result,
        new_article_list: !new_article_list.error
          ? new_article_list.result
          : [],
        popular_article_list: !popular_article_list.error
          ? popular_article_list.result
          : [],
        suggested_article_list: !suggested_article_list.error
          ? suggested_article_list.result
          : []
      }
    };
  }
};
