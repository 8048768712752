import {
    Pagination,
    Box
} from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react'; 
import { connect } from 'react-redux';
import { countPageNumbers, selectPage } from '../../redux/actions';

const mapStateToProps = state => {
    return {
        pageCount: state.pageNumbering.pageCount,
        value: state.pageNumbering.value
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCountPages: () => dispatch(countPageNumbers()),
        onClick: (e, value) => dispatch(selectPage(value))
    }
}

const PageNumbering = ({ pageCount, value, onCountPages, onClick }) => {
    useEffect(() => {
        onCountPages();
    }, [pageCount]);
    return <Pagination
    page={value}
    onChange={onClick}
    hidePrevButton size="large" count={pageCount} sx={{
        width: '100%',
        display: 'grid',
        placeItems: 'center',
        mt: 6,
        '& ul li': {
            margin: { xs: 0.1, sm: 0.3, md: 1 }
        },
        '& ul li button.Mui-selected': {
            background: '#009A9D',
            color: 'white'
        },
        '& ul li button.MuiPaginationItem-previousNext': {
            background: '#F8CB2E',
            color: 'white',
            fontWeight: 'bold',
            transition: 'all 0.3s',
            '&:hover': {
                boxShadow: '2px 2px 4px 2px rgba( 0, 0, 0, 0.2 )',
                color: 'blue'
            }
        }
    }}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(PageNumbering);