import ArticleContainer from '../Layouts/Containers/ArticleContainer';
import { ArticleListItem } from '../Layouts/Posts';
import { useRouter } from 'next/router';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';


const PopularArticles = ({ posts }) => {
    const router = useRouter();
    useEffect(() => {
        AOS.init()
    }, [])
    return (
        <ArticleContainer dataAOS="flip-right" delay="300" heading="Artikel Populer">
            { posts.length && (
                posts.map((post, index) => (
                    <ArticleListItem
                    onClick={() => router.push(`/post/${post.slug.current}`)}
                    key={index}
                    title={post.title}
                    image={post.mainImage}
                    caption={post.body[0].children[0].text}
                    category={post.category}
                    textCount={post}
                    />
                ))
            )}
        </ArticleContainer>
    )
}

export default PopularArticles;