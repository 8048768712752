import {
    Box,
    Typography
} from '@mui/material';

const Heading = ({caption, children}) => (
    <Box>
      <Typography sx={{ 
        mt: { xs: 5, md: '5%' },
        color: 'primary.main',
        fontSize: 42
      }} width="100%" textAlign="center">
        {children}
      </Typography>
      <Typography sx={{ color: '#666666', px: 5 }} textAlign="center" fontWeight="unset" fontFamily="Poppins, sans-serif" mt="10px">
        {caption}
      </Typography>
    </Box> 
)

export default Heading;