import { Typography, Box } from "@mui/material";
import { ArticleListItem } from "../Layouts/Posts";
import { useState, useEffect } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import ArticleContainer from "../Layouts/Containers/ArticleContainer";
import { useRouter } from 'next/router';
import AOS from 'aos';
import "aos/dist/aos.css";


const NewArticles = ({ posts }) => {
    useEffect(() => {
        AOS.init()
    }, []);
    const router = useRouter();
    return (
        <ArticleContainer dataAOS="flip-right" heading="Artikel Terbaru">
            { posts && (
                posts.map((post, index) => (
                    <ArticleListItem
                    onClick={() => router.push(`/post/${post.slug.current}`)}
                    key={index}
                    title={post.title}
                    image={post.mainImage}
                    caption={post.body[0].children[0].text}
                    category={post.category}
                    textCount={post}
                    />
                ))
            )}
        </ArticleContainer>
    )
}

export default NewArticles;