import { Box } from "@mui/material";
import Image from "next/image";

const MainBanner = () => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1020px",
        position: "relative",
        height: { xs: 120, md: 300 }
      }}>
      <Image
        className="main banner-img"
        layout="fill"
        objectFit="contain"
        src={"/banner.jpeg"}
        alt="Promo"
      />
    </Box>
  );
};

export default MainBanner;
