import {
    Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = ({onClick, children}) => {
    return (
        <Button onClick={onClick} startIcon={<ArrowBackIcon size="small"/>}
        sx={{
            color: '#000000',
            opacity: '50%',
            transition: 'opacity 0.2s',
            '&:hover': {
                opacity: '100%'
            }
        }}>
            {children}
        </Button>
    )
}

export default BackButton;